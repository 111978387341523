<!--
 * @Author: your name
 * @Date: 2020-12-25 18:38:56
 * @LastEditTime: 2021-06-01 17:12:55
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \two\src\views\invitemsg\index.vue
-->
<template>
  <div>
    <navigation :msg="msg"></navigation>
    <div class="tuiclass">事业合伙人指南</div>
    <div class="theallmsg">
      <div class="top1class">
        <div></div>
        <div>您已经是萝卜猎手的事业合伙人啦~</div>
      </div>
      <pre>
      萝卜猎手致力于帮助中国1000万家企业降本增效、放心用人。欢迎一起加入我们的事业，我们时刻以您为荣。
      </pre>
      <div class="top1class">
        <div></div>
        <div>事业合伙人有什么权益？</div>
      </div>
      <pre>
      1)千万注意！您在这里生成的叫做<span>事业合伙人专属二维码</span>，转发获得的<span>收益可以提现</span>，而萝卜猎手<span>企业端 “我的”</span>里面生成的<span>推广邀请码</span>，转发获得的<span>余额</span>仅限在萝卜猎手平台消费，<span>不可以提现</span>。
      2)<span class="span1">如何坐享两级收益，实现躺赚？</span>客户A通过您的事业合伙人专属二维码完成注册认证和付费充值，您将按照当前等级，获得客户<span class="span1">A（您的一级客户）</span>充值金额<span class="span1">15%-30%</span>作为奖励（等级和佣金比例请具体咨询客服）。如A又将萝卜猎手推荐给了他的朋友B，B通过A的推广邀请码完成了注册认证和充值，那您还可以额外分享<span class="span1">B（您的二级客户）</span>充值金额的<span class="span1">10%</span>。动动手指，一次分享，您就可以长期分享来自一级客户和二级客户的收益~没错，这就是传说中的<span>睡后收入</span>！
      3)如果您也从事企业服务，联系我们，我们平台<span>客户资源向您开放</span>~我们会优先向平台客户推荐您提供的增值服务； 
      4)排名靠前的事业合伙人可晋升为“超级合伙人”，喜提<span>豪车豪宅</span>、业绩兑换总部股份，<span>上市分红</span>或者回购，更多福利和回报，等你来撩~
      </pre>
      <div class="top1class">
        <div></div>
        <div>事业合伙人二维码的分享方法</div>
      </div>
      <pre>
      1)点击页面右上角的“分享“按钮，生成您的事业合伙人专属海报，保存至相册； 
      2)将您的专属海报图片分享给微信好友、微信朋友圈等社交圈，邀请您的好友扫码完成注册认证和充值，您就可以分享收益啦； 
      3)悄咪咪提醒一句：让您发展的一级客户踊跃转发他在萝卜猎手客户端“我的”邀请有礼二维码，<span>他的成功邀请充值，您都有份</span>哦~
      </pre>
      <div class="top1class">
        <div></div>
        <div>收益相关问题</div>
      </div>
      <pre>
      1)可点击底部菜单栏“我的”--“我的账户”，查看总收益、近期收益及可提现金额； 
      2)<span class="span1">如何看到收益来源？</span>（底部菜单了）“邀请记录”—<span>“邀请收益记录”</span>页面显示了您成功邀请的客户给您带来的收益，<span class="span1">收益指一级客户和二级客户贡献充值给您带来佣金的总和</span>。
      3)<span class="span1">收益如何提现？</span>点击（底部菜单栏）“我的”--“我的账户”--“余额提现”发起提现；提现前请完成至“我的”--“我的账户”--“提现设置”阅读说明和完成银行卡设置。 
        <span class="span1">特别说明</span>：平台支持事业合伙人提现至<span>经指定、非本人的银行卡</span>（详见“提现设置”页面说明）。
      </pre>
      <div class="top1class">
        <div></div>
        <div>其他常见问题说明</div>
      </div>
      <pre>
      1)事业合伙人在发展客户的时候，如遇客户是<span class="span1">平台已注册用户</span>或企业，如事业合伙人可以<span class="span1">推动新的充值</span>，请联系萝卜猎手客服。 
      2)事业合伙人发展下级企业客户，默认绑定下级企业客户的管理员账号，如下级企业客户的管理员账号发生变动（管理员离职，或其他情况导致管理员账号和企业解除绑定），事业合伙人和下级的绑定关系默认解除。如需修改或者恢复绑定关系，请联系萝卜猎手官方客服。 
      3)事业合伙人发展下级（含一级和二级）企业客户的充值，指企业客户管理员账号（企业主账号）和子账号的所有充值。
      </pre>
      <div class="oldmsg">祝我们的事业合伙人万事顺意，心想事成~</div>
    </div>
    <!-- <img class="fxclass" src="../../assets/fx1.jpg" alt=""> -->
  </div>
</template>
<script>
import navigation from "../../components/navigation/index";
export default {
  components: {
    navigation,
  },
  data() {
    return {
      msg: "推广指南",
    };
  },
};
</script>
<style scoped>
.fxclass {
  width: 100%;
}
.span1 {
  color: #f55613;
}
.oldmsg {
  font-size: 0.5rem;
  line-height: 0.68rem;
  font-weight: bold;
  margin-top: 0.8rem;
  padding-bottom: .3rem;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 0.5rem;
  font-size: 0.25rem;
  margin: 0;
  margin-top: 0.24rem;
  font-size: 0.3rem;
  line-height: 0.5rem;
}
pre span {
  font-weight: bold;
}
.theallmsg {
  margin: 0 0.34rem;
}
.top1class > div:first-child {
  width: 0.23rem;
  height: 0.23rem;
  border-radius: 50%;
  background-color: #f55613;
  margin-right: 0.16rem;
}
.top1class {
  font-size: 0.3rem;
  line-height: 0.3rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.tuiclass {
  font-size: 0.5rem;
  line-height: 0.48rem;
  text-align: center;
  margin: 0.6rem 0;
  font-weight: bold;
}
</style>